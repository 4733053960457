import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IStatisticUseCase } from "@/domain/statistic/usecase/IStatisticUsecase";
import { StatisticRepository } from "../repository/StatisticRepository";
import { StatisticMapper } from "../mapper/StatisticMapper";
import { StatisticModel } from "@/domain/statistic/model/StatisticModel";
import { USECASE } from "@/application/constants/Usecase";

export class StatisticUsecase implements IStatisticUseCase {
  getStatisticRepository: StatisticRepository;
  tenantStatisticMapper = new StatisticMapper();

  constructor(_getStatisticRepository: StatisticRepository) {
    this.getStatisticRepository = _getStatisticRepository;
  }

  async execute(): Promise<Result<StatisticModel>> {
    try {
      const response =
        await this.getStatisticRepository.getStatistic();

      if (response.isSuccess) {
        const domain = this.tenantStatisticMapper.mapToDomain(
          response.getValue()
        );

        return Result.ok<StatisticModel>(domain.getValue());
      } else {
        return Result.fail<StatisticModel>(response.getError());
      }
    } catch (error) {
      return Result.fail<StatisticModel>(
        new UseCaseException(new UnknownCause(USECASE.STATISTIC))
      );
    }
  }
}
