export const SWAL_MESSAGES = {
  CONFIRM_BUTTON_TEXT: "swalMessages.CONFIRM_BUTTON_TEXT",
  SURE_DELETE_STAFF: "swalMessages.SURE_DELETE_STAFF",
  ARE_YOU_SURE: "swalMessages.ARE_YOU_SURE",
  SESSION_CLOSED: "swalMessages.SESSION_CLOSED",

  SUCCESS: {
    SUCCESS_GENERATE: "swalMessages.SUCCESS_GENERATE",

    SUCCESS_VERIFY: "swalMessages.SUCCESS_VERIFY",
    SUCCESS_UPDATE_USER: "swalMessages.SUCCESS_UPDATE_USER",

    SUCCESS_ADD_STAFF: "swalMessages.SUCCESS_ADD_STAFF",
    SUCCESS_DELETE_STAFF: "swalMessages.SUCCESS_DELETE_STAFF",

    SUCCESS_BUY_PRODUCT: "swalMessages.SUCCESS_BUY_PRODUCT",
    SUCCESS_UPDATE_PRODUCT_MODEL_SETTING: "swalMessages.SUCCESS_UPDATE_PRODUCT_MODEL_SETTING",

    SUCCESS_SEND_MESSAGE: "swalMessages.SUCCESS_SEND_MESSAGE",
    SUCCESS_RETRY_ANSWER: "swalMessages.SUCCESS_RETRY_ANSWER",
  },
  
  ERROR: {
    SESSION_TIME_OUT: "swalMessages.SESSION_TIME_OUT",
    API_ERROR_MESSAGE: "swalMessages.API_ERROR_MESSAGE",
    ERROR_CODE: "swalMessages.ERROR_CODE",
    UNKNOWN_ERROR_MESSAGE: "swalMessages.UNKNOWN_ERROR_MESSAGE",

    ERROR_GENERATE: "swalMessages.ERROR_GENERATE",

    ERROR_VERIFY: "swalMessages.ERROR_VERIFY",
    ERROR_VERIFY_REMAINING_ATTEMPTS:
      "swalMessages.ERROR_VERIFY_REMAINING_ATTEMPTS",

    TIME_ERROR: "swalMessages.TIME_ERROR",

    ERROR_UPDATE_USER: "swalMessages.ERROR_UPDATE_USER",
    ERROR_CHANGE_TENANT_USER: "swalMessages.ERROR.ERROR_CHANGE_TENANT_USER",
    ERROR_ABOUT_USER: "swalMessages.ERROR_ABOUT_USER",

    ERROR_ADD_STAFF: "swalMessages.ERROR_ADD_STAFF",
    ERROR_DELETE_STAFF: "swalMessages.ERROR_DELETE_STAFF",
    ERROR_STAFF_LIST: "swalMessages.ERROR_STAFF_LIST",

    ERROR_PRODUCT_LIST: "swalMessages.ERROR_PRODUCT_LIST",
    ERROR_BUY_PRODUCT: "swalMessages.ERROR_BUY_PRODUCT",
    ERROR_UPDATE_PRODUCT_MODEL_SETTING: "swalMessages.ERROR_UPDATE_PRODUCT_MODEL_SETTING",

    ERROR_SEND_MESSAGE: "swalMessages.ERROR_SEND_MESSAGE",
    ERROR_GET_SESSION_LIST: "swalMessages.ERROR_GET_SESSION_LIST",
    ERROR_RETRY_ANSWER: "swalMessages.ERROR_RETRY_ANSWER",

    ERROR_GET_STATISTICS_LIST: "swalMessages.ERROR_GET_STATISTICS_LIST",
  },
};
