import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IVerifyOTPService } from "@/domain/auth/verifyOTP/service/IVerifyOTPService";
import { VerifyOTPRequestDto } from "@/domain/auth/verifyOTP/dto/VerifyOTPRequestDto";
import { VerifyOTPResponseDto } from "@/domain/auth/verifyOTP/dto/VerifyOTPResponseDto";

export default class VerifyOTPService implements IVerifyOTPService {
  async verify(
    request: VerifyOTPRequestDto
  ): Promise<Result<VerifyOTPResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.VERIFY_OTP, request)
        .then((response) => {
          return Result.ok<VerifyOTPResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<VerifyOTPResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_VERIFY))
            )
          );
        });
    } catch (error) {
      return Result.fail<VerifyOTPResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.VERIFY_OTP))
      );
    }
  }
}
