export const REPOSITORY = {
    LOGIN: "LoginRepository",
    RESET_PASSWORD: "ResetPasswordRepository",
    FORGOT_PASSWORD: "ForgotPasswordRepository",
    GENERATE_OTP: "GenerateOTPRepository",
    VERIFY_OTP: "VerifyOTPRepository",
    COUNTRY_LIST: "CountryListRepository",
    UPDATE_USER: "UpdateUserRepository",
    ABOUT_USER: "AboutUserRepository",
    CHANGE_TENANT_ESTATE: "ChangeTenantEstateRepository",
    SEND_MESSAGE: "SendMessageRepository",
    RETRY_ANSWER: "RetryAnswerRepository",
    SESSION_LIST: "SessionListRepository",
    STAFF: {
      STAFF_LIST: "StaffListRepository",
      ADD_STAFF: "AddStaffRepository",
      DELETE_STAFF: "DeleteStaffRepository",
    },
    PRODUCT: {
      PRODUCT_LIST: "ProductListRepository",
      BUY_PRODUCT: "BuyProductRepository",
      UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingRepository",
    },
    STATISTIC: "StatisticRepository",
  };