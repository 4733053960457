import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { UpdateUserRepository } from "@/application/user/updateUser/repository/UpdateUserRepository";
import { UpdateUserUsecase } from "@/application/user/updateUser/usecase/UpdateUserUseCase";
import { UpdateUserModel } from "@/domain/user/updateUser/model/UpdateUserModel";
import AboutUserRepository from "@/application/user/aboutUser/repository/AboutUserRepository";
import { AboutUserUseCase } from "@/application/user/aboutUser/usecase/AboutUserUseCase";
import ChangeTenantEstateRepository from "@/application/auth/changeTenantEstate/repository/ChangeTenantEstateRepository";
import { ChangeTenantEstateUseCase } from "@/application/auth/changeTenantEstate/usecase/ChangeTenantEstateUseCase";
import { ChangeTenantEstateModel } from "@/domain/auth/changeTenantEstate/model/ChangeTenantEstateModel";
import { AboutUserModel } from "@/domain/user/aboutUser/model/AboutUserModel";

export function createUserController() {
  const updateUserRepository = new UpdateUserRepository();
  const aboutUserRepository = new AboutUserRepository();
  const changeTenantEstateRepository = new ChangeTenantEstateRepository();

  const updateUserUseCase = new UpdateUserUsecase(updateUserRepository);
  const aboutUserUseCase = new AboutUserUseCase(aboutUserRepository);
  const changeTenantEstateUseCase = new ChangeTenantEstateUseCase(changeTenantEstateRepository);

  return new UserController(updateUserUseCase, aboutUserUseCase, changeTenantEstateUseCase);
}

export class UserController implements IController {
  constructor(
    private updateUserUseCase: UpdateUserUsecase,
    private aboutUserUseCase: AboutUserUseCase,
    private changeTenantEstateUseCase: ChangeTenantEstateUseCase,
  ) {}

  async aboutUser(): Promise<Result<UpdateUserModel>> {
    const response = await this.aboutUserUseCase.execute();

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateUserModel>(response.getError());
    }
  }

  async updateUser(
    updateUserModel: UpdateUserModel
  ): Promise<Result<UpdateUserModel>> {
    const response = await this.updateUserUseCase.execute(updateUserModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateUserModel>(response.getError());
    }
  }

  async changeTenantEstate(model: ChangeTenantEstateModel): Promise<Result<ChangeTenantEstateModel>> {
    const response = await this.changeTenantEstateUseCase.execute(model);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ChangeTenantEstateModel>(response.getError());
    }
  }

}
