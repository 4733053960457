export default {
  project: "Job Assistant",
  year: "2024",
  version: "1.1.6",
  common: {
    pleaseWait: "Please wait...",
    process: "Process",
    loading: "Loading...",
    layout: {
      contact: "Contact Us",
      profile: "My Profile",
      language: "Language",
      signOut: "Sign Out",
    },
    button: {
      create: "Create",
      start: "Start",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      verify: "Verify",
      ok: "OK",
      continue: "Continue",
      send: "Send",
      install: "Install",
      delete: "Delete",
      yes: "Yes",
      no: "No",
    },
    table: {
      process: "Process",
      notProcess: "Cannot be processed as it is the default",
      pagination: {
        desc: " showing from ",
        desc1: " results.",
        desc2: "items per page",
      },
    },
    times: {
      minuteAgo: "minute ago",
      hoursAgo: "hour ago",
      dayAgo: "day ago",
      monthAgo: "month ago",
    }
  },
  asideMenu: {
    modules: {
      header: "Modules",
      activeModules: "Active Modules",
      notFoundActiveModule: "No active module found.",
      hotelManagement: {
        title: "AI Hotel Assistant",
        assistant: "AI Assistant",
        history: "Conversation Archives",
        staffList: {
          title: "Staff",
          desc: "On this page, you can view the staff registered to your company and review past AI assistant conversation records."
        }
      },
      pollster: {
        title: "Pollster",
        polls: "Polls",
        create: "Create Poll",
        emailAddresses: "Email Addresses"
      },
      interview: {
        title: "Interview",
        create: "Create Interview"
      }
    },
    admin: {
      header: "Admin",
      modules: "All Modules",
      accountTypes: "Account Types",
      accountStatus: "Account Status",
      staff: "Staff",
      addStaff: "Add Staff"
    },
    ogza: {
      header: "Ogzatech Management",
      modules: {
        title: "Modules",
        allModules: "All Modules",
        createModul: "Create Module"
      },
      companies: "Registered Companies",
      createCompany: "Create Company",
      chatgptSettings: "ChatGpt Settings"
    },
    user: "User",
  },
  landingPage: {
    menu: {
      home: "Home",
      assistant: "Work Assistant",
      login: "Login"
    },
    headerBanner: {
      title: "Simplify Your Business Process with AI!",
      desc: "Elevate your business processes with AI! Meet our smart automation solutions that increase productivity, save time, and reduce costs. Lighten your workload, focus on important tasks, and multiply your success."
    },
    container: {
      mainTitle: "AI Business Assistant",
      mainHeader: "AI-Powered Business Assistant",
      mainDesc: 
        "Simplify your work significantly with our AI-powered assistant modules. Provide faster and more efficient service to your staff and customers quickly. These modules optimize your business processes, automate repetitive tasks, respond to customer requests instantly, and help you make strategic decisions with data analysis.",
      title: "AI Hotel Assistant",
      desc: 
        "Evaluate customer reviews for your hotel with AI-supported sentiment analysis for a near-realistic assessment.",
      title2: "AI-Powered Pollster",
      desc2: 
        "Conduct internal and external surveys efficiently and quickly with the help of AI. Determine and record the accuracy of responses with sentiment analysis.",
      title3: "Live Interview with AI",
      desc3: 
        "Make your recruitment processes more efficient with AI. Conduct live interviews with candidates, analyze their performance, and identify the most suitable candidates. Thus, make faster and more accurate hiring decisions."
    }
  },
  login: {
    title: "Login",
    desc: "Please enter your phone number to log in.",
    desc2: "Please enter your account information to log in.",
    email: "Email",
    enterEmail: "Please enter your email address...",
    password: "Password",
    enterPassword: "Please enter your password...",
    phone: "Phone Number",
    country: "Country",
    forgotPassword: "Forgot Password",
    button: "Login",
  },
  verifyOTP: {
    title: "SMS Verification",
    desc: "Please enter the verification code sent to your phone via SMS.",
    code: "Verification Code",
    enterCode: "Enter verification code...",
  },
  profileSet: {
    title: "Account Information",
    title2: "Create Profile",
    desc: "Please update your account information...",
    desc2: "Please create your account...",
    name: "Name",
    enterName: "Enter your name...",
    surname: "Surname",
    enterSurname: "Enter your surname...",
    email: "Email",
    enterEmail: "Enter your email address...",
    company: "Company",
    enterCompany: "Enter company name...",
    button: "Save and Login",
  },
  companies: {
    createCompany: "Create New Company",
    createCompanyDesc: "Use AI modules in your own company and streamline your workflow.",
  },
  createCompany: {
    title: "Create Company",
    desc: "Please enter your company information...",
    companyName: "Company Name",
    enterCompanyName: "Enter company name...",
    companyDesc: "Company Description",
    enterCompanyDesc: "Describe your company...",
    sector: "Sector",
    enterSector: "Enter sector...",
  },
  dashboard: {
    title: "Dashboard",
    chart: {
      title: "Total Transaction Count",
      desc: "Graph of total transactions for the last 7 days",
    },
    sections: {
      totalProcessCount: "Total Record Count",
      modalCount: "Module Count",
      staffCount: "Staff Count",
      todaysprocessCount: "Today's Transaction Count",
    },
    lastProcess: {
      title: "Latest Records",
      type: "RECORD TYPE",
      nameSurname: "NAME SURNAME",
      email: "EMAIL",
      time: "COMPLETION TIME",
    },
  },
  profile: {
    title: "Profile",
    personelInfo: "Personal Information",
    name: "Name",
    enterName: "Enter your name",
    surname: "Surname",
    enterSurname: "Enter your surname",
    company: "Company",
    enterCompany: "Enter company name",
    phone:"Phone",
    enterPhone: "Enter your phone number...",
    email: "Email",
    enterEmail: "Enter your email address...",
  },
  modals: {
    detail: {
      pageTitle: "Module Details",
      current: "Current",
      modalDescription: "Module Description",
      packages: "Packages",
      numberOfUses: "Number of Uses",
      dayCount: "Number of Days",
      purchased: "Purchased",
      free: "Free",
      FAQS: "Frequently Asked Questions",
      settings: "Module Settings",
      activeAiModel: "Active AI Model",
      details: {
        title: "Details",
        update: "Update",
        techSupport: "Technical Support",
        licence: "License",
        installation: "Installation",
        lifeLong: "Lifetime",
        full: "Full",
        oneClick: "One-Click",
      }
    },
    hotel: {
      title: "AI Hotel Assistant",
      search: "Search...",
      startNewSession: "Start New Chat",
      newSession: "New Chat",
      sessionCount: "Remaining Session Count",
      zeroSession: "No chat history found.",
      enterMessage: "Your message...",
      emptyMessage: "Please do not send empty messages.",
      newChatDesc:
        "Welcome to the hotel assistant! Please send your topic of discussion or customer review related to your hotel. Our AI will provide realistic responses with its exceptional capabilities to ensure customer satisfaction.",
      closedSession:
        "This chat session has been completed. Please click on the 'Start New Chat' button on the left menu to begin a new chat.",
      staffList: {
        pageTitle: "Staff Members",
        title: "Staff List",
        desc: "You can view the staff registered to your company and review past AI assistant module chat records on this page.",
        addStaff: {
          title: "Add Staff",
          createStaff: "Create Staff Record",
          name: "Staff Name",
          enterName: "Enter name...",
          surname: "Staff Surname",
          enterSurname: "Enter surname...",
          email: "Staff Email",
          enterEmail: "Enter email address...",
          phone: "Staff Phone",
          enterPhone: "Enter phone number...",
          birthDate: "Birth Date",
          enterBirthDate: "Enter birth date...",
          department: "Department",
          enterDepartment: "Enter department...",
          position: "Position",
          enterPosition: "Enter position...",
        },
        name: "Name",
        surname: "Surname",
        phone: "Phone Number",
        email: "Email",
        department: "Department",
        mission: "Mission",
        sessionCount: "Chat Count",
        zeroCount: "No staff records found.",
      },
    },
    pollster: {
      title: "Pollster",
      createPollster: "Create Poll",
      createLink: "Create Link",
      staffPoll: "Staff Poll",
      emailPoll: "Registered Email Poll",
      name: "Name",
      enterName: "Enter name...",
      surname: "Surname",
      enterSurname: "Enter surname...",
      email: "Email",
      enterEmail: "Enter email address...",
      link: "Poll Link",
      staff: "Staff",
      sessionCode: "Session Code",
      enterSessionCode: "Enter session code...",
      pollsHistorys: "Poll History",
      isStaff: "IS STAFF?",
      nameSurname: "NAME SURNAME",
      date: "Completion Time",
      emails: {
        title: "Email Addresses",
        desc: "Manage email addresses registered in the pollster application on this page.",
        createNewEmail: "Add New Email",
        createEmailTitle: "Add Email",
        createEmailDesc: "Enter the email address you want to add to the module",
        email: "Email",
        enterEmail: "Enter email address...",
        pollCount: "Poll Count",
      },
      startPoll: {
        title: "Start Poll",
        desc: "Please enter your personal information to start the poll...",
        name: "Name",
        enterName: "Enter your name...",
        surname: "Surname",
        enterSurname: "Enter your surname...",
      },
      firstAiMessage:
        "Hello, I'm Pelin. Please answer the questions I will ask you accurately. All your feedback will be recorded and evaluated to improve our products.",
      pushToTalk: "Push to talk...",
      percentComplete: "Completion Percentage: ",
      aiPollster: "AI Pollster",
      active: "Active",
      deActive: "Inactive",
      endConversation: "End Conversation",
      time: "Time",
    },
    interview: {
      title: "Interview",
      createInterview: "Create Interview",
      name: "Name",
      enterName: "Enter name...",
      surname: "Surname",
      enterSurname: "Enter surname...",
      email: "Email",
      enterEmail: "Enter email address...",
      link: "Interview Link",
      interviewsHistorys: "Interview History",
      nameSurname: "NAME SURNAME",
      date: "Completion Time",
      startInterview: {
        title: "Start Interview",
        desc: "Please enter your personal information to start the interview...",
        department: "Department",
        enterDepartment: "Enter department...",
        mission: "Mission",
        enterMission: "Enter mission...",
        reason: "Why Do You Want to Work With Us?",
        enterReason: "Explain...",
        experience: "Years of Experience",
        graduate: "Recent Graduate",
        oneYear: "0-1 Years",
        twoYears: "2-3 Years",
        fourYears: "4-5 Years",
        moreSixYears: "6 Years and Above",
      },
      firstAiMessage:
        "Hello, I'm the AI interviewer. I'm here to get to know you. Please feel comfortable and answer my questions naturally.",
      pushToTalk: "Hold and speak...",
      percentComplete: "Completion Percentage: ",
      aiInterviewer: "AI Interviewer",
      active: "Active",
      deActive: "Inactive",
      endConversation: "End Conversation",
      time: "Time",
    },

  },
  modalList: {
    title: "Module List",
    allModules: "All",
    myPurchases: "My Purchases",
    freeOnes: "Free Ones",
    availableForPurchase: "Available for Purchase",
    notFound: "Module not found.",
  },
  ogza: {
    staffList: {
      pageTitle: "Staff",
      title: "Staff List",
      desc: "You can view the staff registered in your company and review their past records on this page.",
      addStaff: {
        title: "Add Staff",
        createStaff: "Create Staff Record",
        name: "Staff Name",
        enterName: "Enter name...",
        surname: "Staff Surname",
        enterSurname: "Enter surname...",
        email: "Staff Email",
        enterEmail: "Enter email address...",
        phone:"Staff Phone",
        enterPhone: "Enter phone number...",
        birthDate: "Birth Date",
        enterBirthDate: "Enter birth date...",
        department: "Department",
        enterDepartment: "Enter department...",
        position: "Position",
        enterPosition: "Enter position...",
      },
      name: "Name",
      surname: "Surname",
      email: "Email",
      phone: "Phone",
      department: "Department",
      mission: "Position",
      zeroCount: "No staff records found.",
    },
    chatGptSettings: {
      title: "ChatGPT Settings",
      activeVersion: "Active ChatGPT Version",
      selectVersion: "Select Version...",
      apiKey: "API Key",
      enterApiKey: "Enter API Key...",
    },
    createModal: {
      title: "Create Module",
      modalIcon: "Module Icon",
      modalIconDesc: "Allowed file types: png, jpg, jpeg..",
      modalName: "Module Name",
      enterModalName: "Enter module name...",
      modalDesc: "Module Description",
      enterModalDesc: "Enter module description...",
      price: "Price",
      enterPrice: "Enter price...",
      detailedDesc: "Detailed Description",
      enterDetailedDesc: "Enter description...",
      question1: "Question 1",
      question2: "Question 2",
      enterQuestion: "Enter question...",
      answer: "Answer",
      enterAnswer: "Enter answer...",
      adminPropmt: "Module Admin Prompt",
      enterPrompt: "Enter prompt...",
      userSessionLimit: "User Session Limit",
      enterLimit: "Enter limit...",
      details: "Details",
      speakOutLoud: "Speak Out Loud",
      videoCall: "Video Call",
      guestUser: "Can guest users access?",
    },
    createCompany: {
      title: "Create Company",
      companyName: "Company Name",
      enterCompanyName: "Enter company name...",
      companyCategory: "Business Category",
      enterCompanyCategory: "Enter category...",
      managerName: "Manager's Name",
      enterManagerName: "Enter manager's name...",
      managerSurname: "Manager's Surname",
      enterManagerSurname: "Enter manager's surname...",
      email: "Email Address",
      enterEmail: "Enter email address...",
      country: "Country",
      phone: "Phone Number",
      enterPhone: "Enter phone number...",
      department: "Department",
      enterDepartment: "Enter department...",
      accountType: "Account Type",
      selectAccountType: "Select account type...",
    },
  },
  accountStatus: {
    pageTitle: "Account Status",
    title: "Account Information",
    companyName: "Company Name",
    enterCompanyName: "Enter company name...",
    email: "Email",
    enterEmail: "Enter email address...",
    accountType: "Account Type",
    activeModalCount: "Active Module Count",
    processCountofPeriod: "Transaction Count of Period",
    purchaseHistory: "Purchase History",
    receivedModule: "Received Module",
    price: "Price",
    procesDate: "Transaction Date",
  },
  accountTypes: {
    title: "Account Types",
    basic: "Basic Account",
    yourActiveAccount: "Your Active Account",
  },
  error404: {
    nothing: "Looks like there's nothing here",
    return: "Return to Homepage",
  },
  error500: {
    title: "System Error",
    desc: "You are not authorized to access this page!",
    return: "Return to Homepage",
  },
  swalMessages: {
    SESSION_TIME_OUT:
      "Session expired. Please log in again with your user information.",
  
    CONFIRM_BUTTON_TEXT: "OK",
    API_ERROR_MESSAGE:
      "Technical error. Unable to connect to the server, please try again.",
    ERROR_CODE: " Error code: ",
    UNKNOWN_ERROR_MESSAGE: "An unknown error occurred.",
    ARE_YOU_SURE: "Are you sure?",
    SURE_DELETE_STAFF: "Are you sure you want to delete this staff member?",
  
    ERROR_GENERATE:
      "Verification code sending failed! Please try again.",
    SUCCESS_GENERATE: "Verification code sent successfully!",
  
    ERROR_VERIFY: "Verification failed! Please try again.",
    SUCCESS_VERIFY: "Verification successful!",
    ERROR_VERIFY_REMAINING_ATTEMPTS:
      "You have exceeded the retry limit. Please try again later.",
    TIME_ERROR: "Session expired. Please log in again.",
  
    ERROR_UPDATE_USER:
      "Failed to update user information. Please try again.",
    SUCCESS_UPDATE_USER: "Successfully updated personal information!",
    ERROR_CHANGE_TENANT_USER:
      "Technical error! Tenant change operation failed.",
    ERROR_ABOUT_USER: "Technical error! Failed to upload personal information.",
  
    ERROR_ADD_STAFF: "Technical error! Failed to register staff.",
    SUCCESS_ADD_STAFF: "Staff registered successfully!",
    ERROR_DELETE_STAFF: "Technical error! Failed to delete staff.",
    SUCCESS_DELETE_STAFF: "Staff deleted successfully!",
    ERROR_STAFF_LIST: "Technical error! Failed to list staff.",
  
    ERROR_PRODUCT_LIST: "Technical error! Failed to list modules.",
    SUCCESS_BUY_PRODUCT: "Congratulations, module purchased successfully!",
    ERROR_BUY_PRODUCT:
      "Transaction failed! Module purchase unsuccessful. Please contact the administrator.",
    SUCCESS_UPDATE_PRODUCT_MODEL_SETTING:
      "Module model successfully changed.",
    ERROR_UPDATE_PRODUCT_MODEL_SETTING:
      "Operation failed! Module model could not be changed.",
  
    ERROR_SEND_MESSAGE:
      "You have reached your limit and cannot start a new session. Please upgrade your account.",
    SUCCESS_SEND_MESSAGE: "Congratulations, message sent successfully.",
    ERROR_GET_SESSION_LIST:
      "Technical error, failed to retrieve session history.",
    SUCCESS_RETRY_ANSWER: "Congratulations, answer successfully retried.",
    ERROR_RETRY_ANSWER:
      "You have reached your limit and cannot create a response. Please upgrade your account.",
  
    SESSION_CLOSED:
      "The chat session has ended. To start a new chat, please click on the 'Start New Session' button on the left menu.",
  
    ERROR_GET_STATISTICS_LIST:
      "Technical error! Failed to load statistics.",
  },
  
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Please enter your email address.",
      invalid: "Please enter a valid email address.",
    },
    password: {
      required: "Please enter your password.",
      min: "Password must be at least 6 characters long.",
      match: "Passwords must match.",
    },
    identity: {
      required: "Please enter your identity number.",
      length: "Identity number must consist of 11 digits.",
    },
    credential: {
      required: "Please enter your email address and password.",
      credential: "Invalid email address or password.",
    },
  },
  
  validators_error: {
    required: "Please do not leave this field empty.",
    typeError: "Please enter data of the appropriate type.",
    email: "Please enter a valid email address.",
    minlength: " characters minimum.",
    maxlength: " characters maximum.",
    zeroValue: "Value cannot be less than 0.",
    phoneNumberFull: "Please enter your full phone number.",
    requiredCode: "Please do not leave the verification code empty.",
    codeInvalid: "Please enter a 6-digit verification code.",
    min18age: "Must be at least 18 years old.",
  },
  
};
