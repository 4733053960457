import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";
import { EmotionAnalysisModel } from "../../sendMessage/model/EmotionAnalysisModel";

export class TenantUsersSessionListModel extends BaseDomain {
  session: {
    id?: number;
    title?: string;
    createdAt?: Date;
    productSessionMessages: {
      owner: string;
      text: string;
      createdAt: Date;
      emotionAnalysis?: EmotionAnalysisModel;
    }[];
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  query?: string;
  staffId: number;

  constructor(
    productSessionMessages: {
      owner: string;
      text: string;
      createdAt: Date;
      emotionAnalysis?: EmotionAnalysisModel;
    }[],
    staffId: number,
    id?: number,
    title?: string,
    createdAt?: Date,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto,
    query?: string
  ) {
    super();
    this.session = {
      id: id,
      title: title,
      createdAt: createdAt,
      productSessionMessages: productSessionMessages,
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
    this.query = query;
    this.staffId = staffId;
  }
}
