export const SERVICE = {
    LOGIN: "LoginService",
    RESET_PASSWORD: "ResetPasswordService",
    FORGOT_PASSWORD: "ForgotPasswordService",
    GENERATE_OTP: "GenerateOTPService",
    VERIFY_OTP: "VerifyOTPService",
    COUNTRY_LIST: "CountryListService",
    UPDATE_USER: "UpdateUserService",
    ABOUT_USER: "AboutUserService",
    CHANGE_TENANT_ESTATE: "ChangeTenantEstateService",
    SEND_MESSAGE: "SendMessageService",
    RETRY_ANSWER: "RetryAnswerService",
    SESSION_LIST: "SessionListService",
    STAFF: {
      STAFF_LIST: "StaffListService",
      ADD_STAFF: "AddStaffService",
      DELETE_STAFF: "DeleteStaffService",
    },
    PRODUCT: {
      PRODUCT_LIST: "ProductListService",
      BUY_PRODUCT: "BuyProductService",
      UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingService",
    },
    STATISTIC: "StatisticService",
  };