import { Result } from "@/core/utility/ResultType";
import UpdateProductModelSettingService from "../service/UpdateProductModelSettingService";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { REPOSITORY } from "@/application/constants/Repository";
import { IUpdateProductModelSettingRepository } from "@/domain/product/updateProductModelSetting/repository/IUpdateProductModelSettingRepository";
import { UpdateProductModelSettingResponseDto } from "@/domain/product/updateProductModelSetting/dto/UpdateProductModelSettingResponseDto";
import { UpdateProductModelSettingRequestDto } from "@/domain/product/updateProductModelSetting/dto/UpdateProductModelSettingRequestDto";

const updateProductModelSettingService = new UpdateProductModelSettingService();

export default class UpdateProductModelSettingRepository
  implements IUpdateProductModelSettingRepository
{
  async updateProductModelSetting(
    request: UpdateProductModelSettingRequestDto
  ): Promise<Result<UpdateProductModelSettingResponseDto>> {
    try {
      return updateProductModelSettingService
        .update(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<UpdateProductModelSettingResponseDto>(
              response.getValue()
            );
          } else {
            return Result.fail<UpdateProductModelSettingResponseDto>(
              response.getError()
            );
          }
        })
        .catch((error) => {
          return Result.fail<UpdateProductModelSettingResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(
          new UnknownCause(REPOSITORY.PRODUCT.UPDATE_PRODUCT_MODEL_SETTING)
        )
      );
    }
  }
}
