import { StaffListRepository } from "../repository/StaffListRepository";
import { Result } from "@/core/utility/ResultType";
import { StaffListMapper } from "../mapper/StaffListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IStaffListUseCase } from "@/domain/staff/staffList/usecase/IStaffListUsecase";
import { StaffListModel } from "@/domain/staff/staffList/model/StaffListModel";

export class StaffListUseCase implements IStaffListUseCase {
  staffListRepository: StaffListRepository;
  staffListMapper = new StaffListMapper();

  constructor(_staffListRepository: StaffListRepository) {
    this.staffListRepository = _staffListRepository;
  }

  async execute(model: StaffListModel): Promise<Result<StaffListModel>> {
    try {
      const dto = this.staffListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<StaffListModel>(dto.getError());
      } else {
        const response = await this.staffListRepository.staffList(dto);

        if (response.isSuccess) {
          const domain = this.staffListMapper.mapToDomain(response.getValue());
          return Result.ok<StaffListModel>(domain.getValue());
        } else {
          return Result.fail<StaffListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<StaffListModel>(
        new UseCaseException(new UnknownCause(USECASE.STAFF.STAFF_LIST))
      );
    }
  }
}
