import { BaseDomain } from "@/core/base/BaseDomain";
import { WeeklyTransactionSummaryDto } from "../dto/WeeklyTransactionSummaryDto";

export class StatisticModel extends BaseDomain {
  weeklyTransactionSummary?: WeeklyTransactionSummaryDto[];
  totalTransactionCount?: number;
  todayTransactionCount?: number;
  activeModuleCount?: number;
  tenantUserCount?: number;

  constructor(
    weeklyTransactionSummary?: WeeklyTransactionSummaryDto[],
    totalTransactionCount?: number,
    todayTransactionCount?: number,
    activeModuleCount?: number,
    tenantUserCount?: number
  ) {
    super();
    this.weeklyTransactionSummary = weeklyTransactionSummary;
    this.totalTransactionCount = totalTransactionCount;
    this.todayTransactionCount = todayTransactionCount;
    this.activeModuleCount = activeModuleCount;
    this.tenantUserCount = tenantUserCount;
  }
}
