import { SendMessageRepository } from "../repository/SendMessageRepository";
import { SendMessageMapper } from "../mapper/SendMessageMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ISendMessageUseCase } from "@/domain/hotelManagement/sendMessage/usecase/ISendMessageUsecase";
import { SendMessageModel } from "@/domain/hotelManagement/sendMessage/model/SendMessageModel";

export class SendMessageUsecase implements ISendMessageUseCase {
  sendMessageRepository: SendMessageRepository;
  SendMessageMapper = new SendMessageMapper();

  constructor(_sendMessageRepository: SendMessageRepository) {
    this.sendMessageRepository = _sendMessageRepository;
  }

  async execute(model: SendMessageModel): Promise<Result<SendMessageModel>> {
    try {
      const dto = this.SendMessageMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<SendMessageModel>(dto.getError());
      } else {
        const response = await this.sendMessageRepository.sendMessage(dto);

        if (response.isSuccess) {
          const domain = this.SendMessageMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<SendMessageModel>(domain.getValue());
        } else {
          return Result.fail<SendMessageModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<SendMessageModel>(
        new UseCaseException(new UnknownCause(USECASE.SEND_MESSAGE))
      );
    }
  }
}
