import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { StaffListRepository } from "@/application/staff/staffList/repository/StaffListRepository";
import { StaffListUseCase } from "@/application/staff/staffList/usecase/StaffListUseCase";
import { StaffListModel } from "@/domain/staff/staffList/model/StaffListModel";
import { AddStaffModel } from "@/domain/staff/addStaff/model/AddStaffModel";
import { AddStaffRepository } from "@/application/staff/addStaff/repository/AddStaffRepository";
import { AddStaffUseCase } from "@/application/staff/addStaff/usecase/AddStaffUseCase";
import { DeleteStaffModel } from "@/domain/staff/deleteStaff/model/DeleteStaffModel";
import { DeleteStaffUseCase } from "@/application/staff/deleteStaff/usecase/DeleteStaffUseCase";
import { DeleteStaffRepository } from "@/application/staff/deleteStaff/repository/DeleteStaffRepository";

export function createStaffController() {
  const staffListRepository = new StaffListRepository();
  const addStaffRepository = new AddStaffRepository();
  const deleteStaffRepository = new DeleteStaffRepository();

  const staffListUseCase = new StaffListUseCase(staffListRepository);
  const addStaffUseCase = new AddStaffUseCase(addStaffRepository);
  const deleteStaffUseCase = new DeleteStaffUseCase(deleteStaffRepository);
  return new StaffController(staffListUseCase, addStaffUseCase, deleteStaffUseCase);
}

export class StaffController implements IController {
  constructor(
    private staffListUseCase: StaffListUseCase,
    private addStaffUseCase: AddStaffUseCase,
    private deleteStaffUseCase: DeleteStaffUseCase
  ) {}

  async staffList(
    staffListModel: StaffListModel
  ): Promise<Result<StaffListModel>> {
    const response = await this.staffListUseCase.execute(staffListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<StaffListModel>(response.getError());
    }
  }

  async addStaff(addStaffModel: AddStaffModel): Promise<Result<AddStaffModel>> {
    const response = await this.addStaffUseCase.execute(addStaffModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<StaffListModel>(response.getError());
    }
  }

  async deleteStaff(deleteStaffModel: DeleteStaffModel): Promise<Result<DeleteStaffModel>> {
    const response = await this.deleteStaffUseCase.execute(deleteStaffModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DeleteStaffModel>(response.getError());
    }
  }
}
