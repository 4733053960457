import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { SessionListRepository } from "@/application/hotelManagement/sessionList/repository/SessionListRepository";
import { SessionListUseCase } from "@/application/hotelManagement/sessionList/usecase/SessionListUseCase";
import { SessionListModel } from "@/domain/hotelManagement/sessionList/model/SessionListModel";
import { SendMessageRepository } from "@/application/hotelManagement/sendMessage/repository/SendMessageRepository";
import { SendMessageUsecase } from "@/application/hotelManagement/sendMessage/usecase/SendMessageUseCase";
import { SendMessageModel } from "@/domain/hotelManagement/sendMessage/model/SendMessageModel";
import { RetryAnswerModel } from "@/domain/hotelManagement/retryAnswer/model/RetryAnswerModel";
import { RetryAnswerRepository } from "@/application/hotelManagement/retryAnswer/repository/RetryAnswerRepository";
import { RetryAnswerUseCase } from "@/application/hotelManagement/retryAnswer/usecase/RetryAnswerUseCase";
import { TenantUsersSessionListModel } from "@/domain/hotelManagement/tenantUsersSessionList/model/TenantUsersSessionListModel";
import { TenantUsersSessionListRepository } from "@/application/hotelManagement/tenantUsersSessionList/repository/TenantUsersSessionListRepository";
import { TenantUsersSessionListUseCase } from "@/application/hotelManagement/tenantUsersSessionList/usecase/TenantUsersSessionListUseCase";

export function createSessionController() {
  const sessionListRepository = new SessionListRepository();
  const tenantUsersSessionListRepository = new TenantUsersSessionListRepository();
  const sendMessageRepository = new SendMessageRepository();
  const retryAnswerRepository = new RetryAnswerRepository();

  const sessionListUseCase = new SessionListUseCase(sessionListRepository);
  const tenantUsersSessionListUseCase = new TenantUsersSessionListUseCase(tenantUsersSessionListRepository);
  const sendMessageUseCase = new SendMessageUsecase(sendMessageRepository);
  const retryAnswerUseCase = new RetryAnswerUseCase(retryAnswerRepository);

  return new SessionController(sessionListUseCase, tenantUsersSessionListUseCase, sendMessageUseCase, retryAnswerUseCase);
}

export class SessionController implements IController {
  constructor(
    private sessionListUseCase: SessionListUseCase,
    private tenantUsersSessionListUseCase: TenantUsersSessionListUseCase,
    private sendMessageUseCase: SendMessageUsecase,
    private retryAnswerUseCase: RetryAnswerUseCase
  ) {}

  async sessionList(
    sessionListModel: SessionListModel
  ): Promise<Result<SessionListModel>> {
    const response = await this.sessionListUseCase.execute(sessionListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<SessionListModel>(response.getError());
    }
  }

  async tenantUsersSessionList(
    tenantUsersSessionListModel: TenantUsersSessionListModel 
  ): Promise<Result<TenantUsersSessionListModel>> {
    const response = await this.tenantUsersSessionListUseCase.execute(tenantUsersSessionListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<TenantUsersSessionListModel>(response.getError());
    }
  }

  async sendMessage(
    sendMessageModel: SendMessageModel
  ): Promise<Result<SendMessageModel>> {
    const response = await this.sendMessageUseCase.execute(sendMessageModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<SendMessageModel>(response.getError());
    }
  }

  async retryAnswer(
    retryAnswerModel: RetryAnswerModel
  ): Promise<Result<RetryAnswerModel>> {
    const response = await this.retryAnswerUseCase.execute(retryAnswerModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<RetryAnswerModel>(response.getError());
    }
  }
}
