import { BaseDTO } from "@/core/base/BaseDTO";

export class GenerateOTPRequestDto extends BaseDTO {
  phoneNumber?: string;
  countryCode?: number;

  constructor(phoneNumber?: string, countryCode?: number) {
    super();
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
  }
}
