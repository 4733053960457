import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IAddStaffService } from "@/domain/staff/addStaff/service/IAddStaffService";
import { AddStaffRequestDto } from "@/domain/staff/addStaff/dto/AddStaffRequestDto";
import { AddStaffResponseDto } from "@/domain/staff/addStaff/dto/AddStaffResponseDto";

export default class AddStaffService implements IAddStaffService {
  async add(request: AddStaffRequestDto): Promise<Result<AddStaffResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.STAFF, request)
        .then((response) => {
          return Result.ok<AddStaffResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AddStaffResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_ADD_STAFF)))
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.STAFF.ADD_STAFF))
      );
    }
  }
}
