import { BaseDomain } from "@/core/base/BaseDomain";

export class ProductModelSettingModel extends BaseDomain {
    id?: number;
    name?: string;
    description?: string;
    selected?: Boolean;
  
  constructor(
    id?: number,
    name?: string,
    description?: string,
    selected?: Boolean,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.description = description;
    this.selected = selected;
  }
}
