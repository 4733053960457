import { VerifyOTPRepository } from "../repository/VerifyOTPRepository";
import { VerifyOTPMapper } from "../mapper/VerifyOTPMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IVerifyOTPUseCase } from "@/domain/auth/verifyOTP/usecase/IVerifyOTPUsecase";
import { VerifyOTPModel } from "@/domain/auth/verifyOTP/model/VerifyOTPModel";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { VerifyOTPResponseDto } from "@/domain/auth/verifyOTP/dto/VerifyOTPResponseDto";
import { UserManager } from "@/infrastructure/helper/UserManager";

export class VerifyOTPUsecase implements IVerifyOTPUseCase {
  verifyOTPRepository: VerifyOTPRepository;
  verifyOTPMapper = new VerifyOTPMapper();
  jwtTokenManager = new JwtTokenManager();
  userManager = new UserManager();

  constructor(_verifyOTPRepository: VerifyOTPRepository) {
    this.verifyOTPRepository = _verifyOTPRepository;
  }

  async execute(model: VerifyOTPModel): Promise<Result<VerifyOTPModel>> {
    try {
      const dto = this.verifyOTPMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<VerifyOTPModel>(dto.getError());
      } else {
        const response = await this.verifyOTPRepository.verifyOTP(dto);

        if (response.isSuccess) {
          const value: VerifyOTPResponseDto = response.getValue();
          const domain = this.verifyOTPMapper.mapToDomain(value);

          this.userManager.saveToken(domain.getValue().jwt);
          this.userManager.saveRole(domain.getValue().roleCode);

          return Result.ok<VerifyOTPModel>(domain.getValue());
        } else {
          return Result.fail<VerifyOTPModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<VerifyOTPModel>(
        new UseCaseException(new UnknownCause(USECASE.VERIFY_OTP))
      );
    }
  }
}
