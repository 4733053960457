import {
  MenuComponent,
  ScrollComponent,
  // ToggleComponent,
  // SwapperComponent,
} from "../assets/ts/components";

/**
 * Initialize KeenThemes custom components
 */
setTimeout(() => {
  // ToggleComponent.bootstrap();
  MenuComponent.bootstrap();
  ScrollComponent.bootstrap();
  // SwapperComponent.bootstrap();
}, 500);
