import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { StaffListRequestDto } from "@/domain/staff/staffList/dto/StaffListRequestDto";
import { IStaffListService } from "@/domain/staff/staffList/service/IStaffListService";
import { StaffListResponseDto } from "@/domain/staff/staffList/dto/StaffListResponseDto";

export default class StaffListService implements IStaffListService {
  async getList(
    request: StaffListRequestDto
  ): Promise<Result<StaffListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.STAFF + request.toQueryString())
        .then((response) => {
          return Result.ok<StaffListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<StaffListResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_STAFF_LIST))
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.STAFF.STAFF_LIST))
      );
    }
  }
}
