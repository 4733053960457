import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { DeleteStaffRequestDto } from "@/domain/staff/deleteStaff/dto/DeleteStaffRequestDto";
import { DeleteStaffModel } from "@/domain/staff/deleteStaff/model/DeleteStaffModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";

export class DeleteStaffMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeleteStaffModel> {
    try {
        const userModel: DeleteStaffModel = {
          ok: dto.ok,
        };
        return Result.ok(userModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.DELETE_STAFF))
      );
    }
  }

  mapToDTO(domain: DeleteStaffModel): Result<DeleteStaffRequestDto> {
    try {
      const userRequest: DeleteStaffRequestDto = {
        id: domain.id,
      };

      return Result.ok(userRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.DELETE_STAFF))
      );
    }
  }
}
