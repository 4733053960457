import axios, { AxiosInstance } from "axios";
import { environment } from "../../../../environment";
import { AuthInterceptor } from "../interceptors/AuthInterceptor";
import ResponseInterceptor from "../interceptors/ResponseInterceptor";

const instance = axios.create({
  baseURL: environment.baseApiUrl,
  withCredentials: true,
});

AuthInterceptor(instance);
ResponseInterceptor(instance);

export default instance as AxiosInstance;
