import { GenerateOTPRepository } from "../repository/GenerateOTPRepository";
import { GenerateOTPMapper } from "../mapper/GenerateOTPMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IGenerateOTPUseCase } from "@/domain/auth/generateOTP/usecase/IGenerateOTPUsecase";
import { GenerateOTPModel } from "@/domain/auth/generateOTP/model/GenerateOTPModel";

export class GenerateOTPUsecase implements IGenerateOTPUseCase {
  generateOTPRepository: GenerateOTPRepository;
  GenerateOTPMapper = new GenerateOTPMapper();

  constructor(_generateOTPRepository: GenerateOTPRepository) {
    this.generateOTPRepository = _generateOTPRepository;
  }

  async execute(model: GenerateOTPModel): Promise<Result<GenerateOTPModel>> {
    try {
      const dto = this.GenerateOTPMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<GenerateOTPModel>(dto.getError());
      } else {
        const response = await this.generateOTPRepository.generateOTP(dto);

        if (response.isSuccess) {
          const domain = this.GenerateOTPMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<GenerateOTPModel>(domain.getValue());
        } else {
          return Result.fail<GenerateOTPModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<GenerateOTPModel>(
        new UseCaseException(new UnknownCause(USECASE.GENERATE_OTP))
      );
    }
  }
}
