import { BaseDTO } from "@/core/base/BaseDTO";

export class BuyProductRequestDto extends BaseDTO {
  data: {
    productPackage?: number;
  }
  constructor(
    productPackage?: number,
  ) {
    super();
    this.data = {
      productPackage,
    }
  }
}
