import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { ITenantUsersSessionListService } from "@/domain/hotelManagement/tenantUsersSessionList/service/ITenantUsersSessionListService";
import { TenantUsersSessionListRequestDto } from "@/domain/hotelManagement/tenantUsersSessionList/dto/TenantUsersSessionListRequestDto";
import { TenantUsersSessionListResponseDto } from "@/domain/hotelManagement/tenantUsersSessionList/dto/TenantUsersSessionListResponseDto";

export default class TenantUsersSessionListService implements ITenantUsersSessionListService {
  async getList(
    request: TenantUsersSessionListRequestDto
  ): Promise<Result<TenantUsersSessionListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.STAFF + request.id + API_ROUTE.HOTEL_REVIEW_MODULE + request.toQueryString())
        .then((response) => {
          return Result.ok<TenantUsersSessionListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<TenantUsersSessionListResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_GET_SESSION_LIST)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.SESSION_LIST))
      );
    }
  }
}