import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { TenantUsersSessionListRequestDto } from "@/domain/hotelManagement/tenantUsersSessionList/dto/TenantUsersSessionListRequestDto";
import { TenantUsersSessionListResponseDto } from "@/domain/hotelManagement/tenantUsersSessionList/dto/TenantUsersSessionListResponseDto";
import { TenantUsersSessionListModel } from "@/domain/hotelManagement/tenantUsersSessionList/model/TenantUsersSessionListModel";

const paginationMapper = new PaginationMapper();

export class TenantUsersSessionListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: TenantUsersSessionListResponseDto): Result<TenantUsersSessionListModel[]> {
    try {
      const tenantUsersSessionList: TenantUsersSessionListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const productSessionMessages =
            item.attributes.productSessionMessages?.data.map((log) => ({
              owner: log.attributes.owner,
              text: log.attributes.text,
              createdAt: log.attributes.createdAt,
              emotionAnalysis: log.attributes.emotionAnalysis,
            })) || [];

            const tenantUsersSessionListModel: TenantUsersSessionListModel = new TenantUsersSessionListModel(
            productSessionMessages,
            0,
            item.id,
            item.attributes.title,
            item.attributes.createdAt,
            0,
            0,
            dto.meta.pagination
          );

          tenantUsersSessionList.push(tenantUsersSessionListModel);
        }
      }
      
      return Result.ok(tenantUsersSessionList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.SESSION_LIST))
      );
    }
  }

  mapToDTO(domain: TenantUsersSessionListModel): Result<TenantUsersSessionListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const countryRequest = new TenantUsersSessionListRequestDto(Number(domain.staffId), pagiRequest, domain.query, );

      return Result.ok(countryRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.SESSION_LIST))
      );
    }
  }
}
