import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { Actions, Mutations } from "../enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import instance from "@/infrastructure/network/axios/AxiosService";

export interface UserAuthInfo {
  errors: Array<string>;
  user: any;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as any;
  jwtTokenManager = new JwtTokenManager();
  isAuthenticated = !!this.jwtTokenManager.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): any {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH]() {
    this.isAuthenticated = true;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as any;
    this.errors = [];
    this.jwtTokenManager.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials: any) {
    return new Promise<void>((resolve, reject) => {
      const loginDataRequest: AxiosRequestConfig = {
        data: credentials,
      };

      instance
        .post("auth/login/", loginDataRequest)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      instance
        .post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (this.jwtTokenManager.getToken()) {
      //  DefaultHttpClient.setHeader();
      this.context.commit(Mutations.SET_AUTH);
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
