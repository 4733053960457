import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { CountryListRepository } from "@/application/country/countryList/repository/CountryListRepository";
import { CountryListUseCase } from "@/application/country/countryList/usecase/CountryListUseCase";
import { CountryListModel } from "@/domain/country/countryList/model/CountryListModel";

export function createCountryController() {
  const countryListRepository = new CountryListRepository();

  const countryListUseCase = new CountryListUseCase(countryListRepository);
  return new CountryController(countryListUseCase);
}

export class CountryController implements IController {
  constructor(private countryListUseCase: CountryListUseCase) {}

  async countryList(
    countryListModel: CountryListModel
  ): Promise<Result<CountryListModel>> {
    const response = await this.countryListUseCase.execute(countryListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CountryListModel>(response.getError());
    }
  }
}
