import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { AddStaffRequestDto } from "@/domain/staff/addStaff/dto/AddStaffRequestDto";
import { AddStaffModel } from "@/domain/staff/addStaff/model/AddStaffModel";

export class AddStaffMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<any> {
    try {
      const addStaffModel: AddStaffModel = {
        name: dto.data.attributes.name,
        surname: dto.data.attributes.surname,
        email: dto.data.attributes.email,
        phoneNumber: dto.data.attributes.phoneNumber,
        countryCode: dto.data.attributes.countryCode,
        department: dto.data.attributes.department,
        position: dto.data.attributes.position,
      };

      return Result.ok(addStaffModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.ADD_STAFF))
      );
    }
  }

  mapToDTO(domain: AddStaffModel): Result<AddStaffRequestDto> {
    try {
      const addStaffRequest = new AddStaffRequestDto(
        domain.name,
        domain.surname,
        domain.email,
        domain.phoneNumber,
        domain.countryCode,
        domain.department,
        domain.position,
      );

      return Result.ok(addStaffRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.ADD_STAFF))
      );
    }
  }
}
