
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IGetPurchasedProductsUseCase } from "@/domain/product/getPurchasedProducts/usecase/IGetPurchasedProductsUsecase";
import { GetPurchasedProductsRepository } from "../repository/GetPurchasedProductsRepository";
import { GetPurchasedProductsMapper } from "../mapper/GetPurchasedProductsMapper";
import { GetPurchasedProductsModel } from "@/domain/product/getPurchasedProducts/model/GetPurchasedProductsModel";

export class GetPurchasedProductsUseCase implements IGetPurchasedProductsUseCase {
  productListRepository: GetPurchasedProductsRepository;
  productListMapper = new GetPurchasedProductsMapper();

  constructor(_productListRepository: GetPurchasedProductsRepository) {
    this.productListRepository = _productListRepository;
  }

  async execute(model: GetPurchasedProductsModel): Promise<Result<GetPurchasedProductsModel>> {
    try {
      const dto = this.productListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<GetPurchasedProductsModel>(dto.getError());
      } else {
        const response = await this.productListRepository.productList(dto);

        if (response.isSuccess) {
          const domain = this.productListMapper.mapToDomain(response.getValue());
          return Result.ok<GetPurchasedProductsModel>(domain.getValue());
        } else {
          return Result.fail<GetPurchasedProductsModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<GetPurchasedProductsModel>(
        new UseCaseException(new UnknownCause(USECASE.PRODUCT.PRODUCT_LIST))
      );
    }
  }
}
