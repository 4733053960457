import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IStatisticService } from "@/domain/statistic/service/IStatisticService";
import { StatisticResponseDto } from "@/domain/statistic/dto/StatisticResponseDto";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";

export default class StatisticService implements IStatisticService {
  async get(): Promise<Result<StatisticResponseDto>> {
    try {
      return await instance
        .get<StatisticResponseDto>(API_ROUTE.STATISTIC)
        .then((response) => {
          return Result.ok<StatisticResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<StatisticResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_GET_STATISTICS_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<StatisticResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.STATISTIC))
      );
    }
  }
}
