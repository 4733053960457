import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { SendMessageRequestDto } from "@/domain/hotelManagement/sendMessage/dto/SendMessageRequestDto";
import { SendMessageResponseDto } from "@/domain/hotelManagement/sendMessage/dto/SendMessageResponseDto";
import { SendMessageModel } from "@/domain/hotelManagement/sendMessage/model/SendMessageModel";

export class SendMessageMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: SendMessageResponseDto): Result<SendMessageModel> {
    try {
      const productSessionMessages =
        dto.data.attributes.productSessionMessages.data.map((log) => ({
          messageId: Number(log.id),
          owner: log.attributes.owner,
          text: log.attributes.text,
          createdAt: log.attributes.createdAt,
          emotionAnalysis: log.attributes.emotionAnalysis,
        })) || [];
  
      const sendMessageModel: SendMessageModel = new SendMessageModel(
        productSessionMessages,
        "",
        dto.data.id,
        dto.data.attributes.title,
        dto.data.attributes.createdAt,
      );
  
      return Result.ok(sendMessageModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.SEND_MESSAGE))
      );
    }
  }

  mapToDTO(domain: SendMessageModel): Result<SendMessageRequestDto> {
    try {
      const sendMessageRequest = new SendMessageRequestDto(domain.text);

      return Result.ok(sendMessageRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.SEND_MESSAGE))
      );
    }
  }
}
