import { BaseTokenManager } from "@/core/base/BaseTokenManager";
import { LocalStorage } from "@/core/storage/LocalStorage";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
const localStorage = new LocalStorage();
export class JwtTokenManager implements BaseTokenManager {
  getToken = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE.JWT);
  };

  saveToken = (token: string): void => {
    localStorage.setItem(LOCAL_STORAGE.JWT, token);
  };

  destroyToken = (): void => {
    localStorage.removeItem(LOCAL_STORAGE.JWT);
    localStorage.clear(); //bunu burada yapmaman lazım
  };
}
