import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { PaginationModel } from "../model/PaginationModel";
import { PaginationRequestDto } from "../dto/PaginationRequestDto";
import { PaginationResponseDto } from "../dto/PaginationResponseDto";

export class PaginationMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: PaginationResponseDto): PaginationModel {
    const paginationModel = new PaginationModel();
    return paginationModel;
  }

  mapToDTO(domain: PaginationModel): Result<PaginationRequestDto> {
    const paginationRequest = new PaginationRequestDto({
      page: domain.page,
      pageSize: domain.pageSize,
    });

    return Result.ok(paginationRequest);
  }
}
