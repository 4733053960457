import { DeleteStaffRepository } from "../repository/DeleteStaffRepository";
import { DeleteStaffModel } from "@/domain/staff/deleteStaff/model/DeleteStaffModel";
import { IDeleteStaffUseCase } from "@/domain/staff/deleteStaff/usecase/IDeleteStaffUseCase";
import { DeleteStaffMapper } from "../mapper/DeleteStaffMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class DeleteStaffUseCase implements IDeleteStaffUseCase {
  deleteUserRepository: DeleteStaffRepository;
  deleteUserMapper = new DeleteStaffMapper();

  constructor(_deleteUserRepository: DeleteStaffRepository) {
    this.deleteUserRepository = _deleteUserRepository;
  }

  async execute(model: DeleteStaffModel): Promise<Result<DeleteStaffModel>> {
    try {
      const dto = this.deleteUserMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteStaffModel>(dto.getError());
      } else {
        const response = await this.deleteUserRepository.deleteUser(dto);

        if (response.isSuccess) {
          const domain = this.deleteUserMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteStaffModel>(domain.getValue());
        } else {
          return Result.fail<DeleteStaffModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteStaffModel>(
        new UseCaseException(new UnknownCause(USECASE.STAFF.DELETE_STAFF))
      );
    }
  }
}
