import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { AxiosInstance } from "axios";

const jwtTokenManager = new JwtTokenManager();

export const AuthInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await jwtTokenManager.getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
