import { BadRequestCause } from "@/core/cause/BadRequestCause";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { Result } from "@/core/utility/ResultType";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import instance from "@/infrastructure/network/axios/AxiosService";
import i18n from "@/presentation/language/i18n";
import { IUpdateProductModelSettingService } from "@/domain/product/updateProductModelSetting/service/IUpdateProductModelSettingService";
import { UpdateProductModelSettingRequestDto } from "@/domain/product/updateProductModelSetting/dto/UpdateProductModelSettingRequestDto";
import { UpdateProductModelSettingResponseDto } from "@/domain/product/updateProductModelSetting/dto/UpdateProductModelSettingResponseDto";

export default class UpdateProductModelSettingService
  implements IUpdateProductModelSettingService
{
  async update(
    request: UpdateProductModelSettingRequestDto
  ): Promise<Result<UpdateProductModelSettingResponseDto>> {
    try {
      return await instance
        .put<UpdateProductModelSettingResponseDto>(
          API_ROUTE.PRODUCT_PACKAGE + request.productId,
          request
        )
        .then((response) => {
          return Result.ok<UpdateProductModelSettingResponseDto>(response.data);
        })
        .catch((error) => {
          return Result.fail<UpdateProductModelSettingResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_UPDATE_PRODUCT_MODEL_SETTING)))
          );
        });
    } catch (error) {
      return Result.fail<UpdateProductModelSettingResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.PRODUCT.UPDATE_PRODUCT_MODEL_SETTING))
      );
    }
  }
}
