import { BaseDTO } from "@/core/base/BaseDTO";

export class AddStaffRequestDto extends BaseDTO {
  data: {
    name?: string;
    surname?: string;
    email?: string;
    phoneNumber?: string;
    countryCode?: number;
    department?: string;
    position?: string;
  }
  constructor(
    name?: string,
    surname?: string,
    email?: string,
    phoneNumber?: string,
    countryCode?: number,
    department?: string,
    position?: string,
  ) {
    super();
    this.data = {
      name,
      surname,
      email,
      phoneNumber,
      countryCode,
      department,
      position
    }
  }
}
