import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { RetryAnswerRequestDto } from "@/domain/hotelManagement/retryAnswer/dto/RetryAnswerRequestDto";
import { IRetryAnswerService } from "@/domain/hotelManagement/retryAnswer/service/IRetryAnswerService";
import { RetryAnswerResponseDto } from "@/domain/hotelManagement/retryAnswer/dto/RetryAnswerResponseDto";

export default class RetryAnswerService implements IRetryAnswerService {
  async retry(
    request: RetryAnswerRequestDto
  ): Promise<Result<RetryAnswerResponseDto>> {
    try {
      return await instance
        .get<RetryAnswerResponseDto>(API_ROUTE.HOTEL_REVIEW_MODULE + request.id + API_ROUTE.RETRY)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<RetryAnswerResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_RETRY_ANSWER)))
          );
        });
    } catch (error) {
      return Result.fail<RetryAnswerResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.RETRY_ANSWER))
      );
    }
  }
}
