export const USECASE = {
    LOGIN: "LoginUsecase",
    RESET_PASSWORD: "ResetPasswordUsecase",
    FORGOT_PASSWORD: "ForgotPasswordUsecase",
    GENERATE_OTP: "GenerateOTPUsecase",
    VERIFY_OTP: "VerifyOTPUsecase",
    COUNTRY_LIST: "CountryListUsecase",
    UPDATE_USER: "UpdateUserUsecase",
    ABOUT_USER: "AboutUserUsecase",
    CHANGE_TENANT_ESTATE: "ChangeTenantEstateUsecase",
    SEND_MESSAGE: "SendMessageUsecase",
    RETRY_ANSWER: "RetryAnswerUsecase",
    SESSION_LIST: "SessionListUsecase",
    STAFF: {
      STAFF_LIST: "StaffListUsecase",
      ADD_STAFF: "AddStaffUsecase",
      DELETE_STAFF: "DeleteStaffUsecase",
    },
    PRODUCT: {
      PRODUCT_LIST: "ProductListUsecase",
      BUY_PRODUCT: "BuyProductUsecase",
      UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingUsecase",
    },
    STATISTIC: "StatisticUsecase",
  };