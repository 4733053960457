import { LocalStorage } from "@/core/storage/LocalStorage";
import { JwtTokenManager } from "../storage/JwtTokenManager";
import { TenantEstateResponseDto } from "@/domain/user/tenantEstate/dto/TenantEstateResponseDto";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
import { AES256EncryptionService } from "../encryption/AES256EncryptionService";

const localStorage = new LocalStorage();
const jwtTokenManager = new JwtTokenManager();

export class UserManager {
  saveToken(jwt: string) {
    jwtTokenManager.saveToken(jwt);
  }

  saveRole(roleCode: string) {
    const encryptedRoleCode = AES256EncryptionService.encrypt(
      JSON.stringify(roleCode)
    );

    const decryptedRoleCode =
      AES256EncryptionService.decrypt(encryptedRoleCode);
    console.log("rol", roleCode, encryptedRoleCode, decryptedRoleCode);
    localStorage.setItem("roleCode", encryptedRoleCode);
  }

  setActiveTenantName(response: TenantEstateResponseDto) {
    localStorage.setItem(
      LOCAL_STORAGE.ACTIVE_TENANT,
      response.data.attributes.shortCode
    );
  }
}
