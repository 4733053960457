import { AddStaffRepository } from "../repository/AddStaffRepository";
import { AddStaffModel } from "@/domain/staff/addStaff/model/AddStaffModel";
import { Result } from "@/core/utility/ResultType";
import { AddStaffMapper } from "../mapper/AddStaffMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAddStaffUseCase } from "@/domain/staff/addStaff/usecase/IAddStaffUsecase";

export class AddStaffUseCase implements IAddStaffUseCase {
  addStaffRepository: AddStaffRepository;
  addStaffMapper = new AddStaffMapper();

  constructor(_addStaffRepository: AddStaffRepository) {
    this.addStaffRepository = _addStaffRepository;
  }

  async execute(model: AddStaffModel): Promise<Result<AddStaffModel>> {
    try {
      const dto = this.addStaffMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AddStaffModel>(dto.getError());
      } else {
        const response = await this.addStaffRepository.addStaff(dto);

        if (response.isSuccess) {
          const domain = this.addStaffMapper.mapToDomain(response.getValue());

          return Result.ok<AddStaffModel>(domain.getValue());
        } else {
          return Result.fail<AddStaffModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<AddStaffModel>(
        new UseCaseException(new UnknownCause(USECASE.STAFF.ADD_STAFF))
      );
    }
  }
}
