import { App } from "vue";
import VueAxios from "vue-axios";
import AxiosService from "./AxiosService";

export class AxiosClient {
  public static vueInstance: App;

  public static async setInstance(app: App<Element>): Promise<void> {
    AxiosClient.vueInstance = app;
    AxiosClient.vueInstance.use(VueAxios, AxiosService);
  }
}
