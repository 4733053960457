import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { VerifyOTPRequestDto } from "@/domain/auth/verifyOTP/dto/VerifyOTPRequestDto";
import { VerifyOTPResponseDto } from "@/domain/auth/verifyOTP/dto/VerifyOTPResponseDto";
import { VerifyOTPModel } from "@/domain/auth/verifyOTP/model/VerifyOTPModel";

export class VerifyOTPMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: VerifyOTPResponseDto): Result<VerifyOTPModel> {
    try {
      const verifyOTPModel: VerifyOTPModel = {
        jwt: dto.jwt,
        phoneNumber: dto.data.attributes.phoneNumber,
        id: dto.data.id,
        username: dto.data.attributes.username,
        email: dto.data.attributes.email,
        name: dto.data.attributes.name?.toString(),
        surname: dto.data.attributes.surname?.toString(),
        remainingQuota: dto.data.attributes.remainingQuota,
        roleCode: dto.data.attributes.activeTenantEstate.data.attributes.tenantRole.data.attributes.shortCode,
        roleName: dto.data.attributes.activeTenantEstate.data.attributes.tenantRole.data.attributes.name,
      };

      return Result.ok(verifyOTPModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.VERIFY_OTP))
      );
    }
  }

  mapToDTO(domain: VerifyOTPModel): Result<VerifyOTPRequestDto> {
    try {
      const verifyOTPRequest = new VerifyOTPRequestDto(
        domain.phoneNumber,
        domain.countryCode,
        domain.otp
      );

      return Result.ok(verifyOTPRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.VERIFY_OTP))
      );
    }
  }
}
