import { createApp } from "vue";
import App from "./App.vue";

import router from "./presentation/router";
import store from "./presentation/store";
import ElementPlus from "element-plus";
import i18n from "./presentation/language/i18n";
import VueTheMask from "vue-the-mask";

//imports for app initialization
import { initApexCharts } from "./presentation/plugins/ApexCharts";
import { initInlineSvg } from "./presentation/plugins/Inline-svg";
import { initVeeValidate } from "./presentation/plugins/Vee-validate";

import './presentation/assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
import './presentation/assets/vendor/php-email-form/validate.js'
import './presentation/assets/vendor/aos/aos.js'
import './presentation/assets/vendor/glightbox/js/glightbox.min.js'
import './presentation/assets/vendor/purecounter/purecounter_vanilla.js'
import './presentation/assets/vendor/swiper/swiper-bundle.min.js'

import "./presentation/plugins/KeenThemes";
import "./presentation/plugins/Prismjs";
import "bootstrap";
import { STORE } from "./core/constants/Store";
import { AxiosClient } from "./infrastructure/network/axios/AxiosClient";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);
app.use(VueTheMask);

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

AxiosClient.setInstance(app).then(() => {
  app.mount("#app");
});

store.dispatch(STORE.CONTROLLERS);
