import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { ProductListRepository } from "@/application/product/productList/repository/ProductListRepository";
import { ProductListUseCase } from "@/application/product/productList/usecase/ProductListUseCase";
import { ProductListModel } from "@/domain/product/productList/model/ProductListModel";
import { BuyProductRepository } from "@/application/product/buyProduct/repository/BuyProductRepository";
import { BuyProductUsecase } from "@/application/product/buyProduct/usecase/BuyProductUseCase";
import { BuyProductModel } from "@/domain/product/buyProduct/model/BuyProductModel";
import UpdateProductModelSettingRepository from "@/application/product/updateProductModelSetting/repository/UpdateProductModelSettingRepository";
import { UpdateProductModelSettingUseCase } from "@/application/product/updateProductModelSetting/usecase/UpdateProductModelSettingUseCase";
import { UpdateProductModelSettingModel } from "@/domain/product/updateProductModelSetting/model/UpdateProductModelSettingModel";
import { GetPurchasedProductsRepository } from "@/application/product/getPurchasedProducts/repository/GetPurchasedProductsRepository";
import { GetPurchasedProductsUseCase } from "@/application/product/getPurchasedProducts/usecase/GetPurchasedProductsUseCase";

export function createProductController() {
  const productListRepository = new ProductListRepository();
  const buyProductRepository = new BuyProductRepository();
  const updateProductModelSettingRepository = new UpdateProductModelSettingRepository();
  const getPurchasedProductsRepository = new GetPurchasedProductsRepository();

  const productListUseCase = new ProductListUseCase(productListRepository);
  const buyProductUseCase = new BuyProductUsecase(buyProductRepository);
  const updateProductModelSettingUseCase = new UpdateProductModelSettingUseCase(updateProductModelSettingRepository);
  const getPurchasedProductsUseCase = new GetPurchasedProductsUseCase(getPurchasedProductsRepository);
  return new ProductController(productListUseCase, buyProductUseCase, updateProductModelSettingUseCase, getPurchasedProductsUseCase);
}

export class ProductController implements IController {
  constructor(private productListUseCase: ProductListUseCase,
    private buyProductUseCase: BuyProductUsecase,
    private updateProductModelSettingUseCase: UpdateProductModelSettingUseCase,
    private getPurchasedProductsUseCase: GetPurchasedProductsUseCase
  ) {}

  async productList(
    productListModel: ProductListModel
  ): Promise<Result<ProductListModel>> {
    const response = await this.productListUseCase.execute(productListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ProductListModel>(response.getError());
    }
  }

  async buyProduct(
    buyProductModel: BuyProductModel
  ): Promise<Result<BuyProductModel>> {
    const response = await this.buyProductUseCase.execute(buyProductModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<BuyProductModel>(response.getError());
    }
  }

  async updateProductModelSetting(
    updateProductModelSettingModel: UpdateProductModelSettingModel
  ): Promise<Result<UpdateProductModelSettingModel>> {
    const response = await this.updateProductModelSettingUseCase.execute(updateProductModelSettingModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateProductModelSettingModel>(response.getError());
    }
  }

  async getPurchasedProducts(
    productListModel: ProductListModel
  ): Promise<Result<ProductListModel>> {
    const response = await this.getPurchasedProductsUseCase.execute(productListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ProductListModel>(response.getError());
    }
  }
}
