import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { SessionListRequestDto } from "@/domain/hotelManagement/sessionList/dto/SessionListRequestDto";
import { SessionListResponseDto } from "@/domain/hotelManagement/sessionList/dto/SessionListResponseDto";
import { SessionListModel } from "@/domain/hotelManagement/sessionList/model/SessionListModel";

const paginationMapper = new PaginationMapper();

export class SessionListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: SessionListResponseDto): Result<SessionListModel[]> {
    try {
      const sessionList: SessionListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const productSessionMessages =
            item.attributes.productSessionMessages.data.map((log) => ({
              messageId: Number(log.id),
              owner: log.attributes.owner,
              text: log.attributes.text,
              createdAt: log.attributes.createdAt,
              emotionAnalysis: log.attributes.emotionAnalysis,
            })) || [];

          const sessionListModel: SessionListModel = new SessionListModel(
            productSessionMessages,
            item.id,
            item.attributes.title,
            item.attributes.createdAt,

            0,
            0,
            dto.meta.pagination
          );

          sessionList.push(sessionListModel);
        }
      }
      return Result.ok(sessionList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.SESSION_LIST))
      );
    }
  }

  mapToDTO(domain: SessionListModel): Result<SessionListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const countryRequest = new SessionListRequestDto(pagiRequest, domain.query);

      return Result.ok(countryRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.SESSION_LIST))
      );
    }
  }
}
