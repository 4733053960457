import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IGetPurchasedProductsRepository } from "@/domain/product/getPurchasedProducts/repository/IGetPurchasedProductsRepository";
import { GetPurchasedProductsRequestDto } from "@/domain/product/getPurchasedProducts/dto/GetPurchasedProductsRequestDto";
import { GetPurchasedProductsResponseDto } from "@/domain/product/getPurchasedProducts/dto/GetPurchasedProductsResponseDto";
import GetPurchasedProductsService from "../service/GetPurchasedProductsService";

const productListService = new GetPurchasedProductsService();

export class GetPurchasedProductsRepository implements IGetPurchasedProductsRepository {
  async productList(
    request: GetPurchasedProductsRequestDto
  ): Promise<Result<GetPurchasedProductsResponseDto>> {
    try {
      return await productListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<GetPurchasedProductsResponseDto>(response.getValue());
          } else {
            return Result.fail<GetPurchasedProductsResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<GetPurchasedProductsResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.PRODUCT.PRODUCT_LIST))
      );
    }
  }
}
