import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import TenantUsersSessionListService from "../service/TenantUsersSessionListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ITenantUsersSessionListRepository } from "@/domain/hotelManagement/tenantUsersSessionList/repository/ITenantUsersSessionListRepository";
import { TenantUsersSessionListRequestDto } from "@/domain/hotelManagement/tenantUsersSessionList/dto/TenantUsersSessionListRequestDto";
import { TenantUsersSessionListResponseDto } from "@/domain/hotelManagement/tenantUsersSessionList/dto/TenantUsersSessionListResponseDto";

const tenantUsersTenantUsersSessionListService = new TenantUsersSessionListService();

export class TenantUsersSessionListRepository implements ITenantUsersSessionListRepository {
  async tenantUsersSessionList(
    request: TenantUsersSessionListRequestDto
  ): Promise<Result<TenantUsersSessionListResponseDto>> {
    try {
      return await tenantUsersTenantUsersSessionListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<TenantUsersSessionListResponseDto>(response.getValue());
          } else {
            return Result.fail<TenantUsersSessionListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<TenantUsersSessionListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.SESSION_LIST))
      );
    }
  }
}
