import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { ChangeTenantEstateResponseDto } from "@/domain/auth/changeTenantEstate/dto/ChangeTenantEstateResponseDto";
import { ChangeTenantEstateModel } from "@/domain/auth/changeTenantEstate/model/ChangeTenantEstateModel";
import { ChangeTenantEstateRequestDto } from "@/domain/auth/changeTenantEstate/dto/ChangeTenantEstateRequestDto";

export class ChangeTenantEstateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(
    dto: ChangeTenantEstateResponseDto
  ): Result<ChangeTenantEstateModel> {
    try {
      return Result.ok(dto);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.CHANGE_TENANT_ESTATE))
      );
    }
  }

  mapToDTO(
    domain: ChangeTenantEstateModel
  ): Result<ChangeTenantEstateRequestDto> {
    try {
      const changeTenantEstateRequest = new ChangeTenantEstateRequestDto(
        domain.tenantEstateId
      );
      return Result.ok(changeTenantEstateRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.CHANGE_TENANT_ESTATE))
      );
    }
  }
}
