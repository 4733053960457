import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import { Actions, Mutations } from "@/presentation/store/enums/StoreEnums";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";

import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { AES256EncryptionService } from "@/infrastructure/encryption/AES256EncryptionService";

const routes: Array<RouteRecordRaw> = [
  {    path: "/",
    name: "landingPage",
    meta: { title: "Yapay Zeka İş Asistan Web Uygulaması" },
    component: () => import("@/presentation/LandingPage.vue"),
  },
  {
    path: "/app",
    component: () => import("@/presentation/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "Anasayfa | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/Dashboard.vue"),
      },
      {
        path: "/version",
        name: "version",
        meta: { title: "Sürüm Güncellemeleri | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN" },
        component: () => import("@/presentation/views/Version.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        meta: { title: "Profilim | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/Profile.vue"),
      },
      {
        path: "/hotelManagement/",
        name: "hotelManagement",
        component: () =>
          import("@/presentation/views/modules/hotelManagement/Chat.vue"),
        children: [
          {
            path: "/hotelManagement/:id",
            name: "hotelManagementHistory",
            meta: {
              title: "Geçmiş | AI Otel Asistanı | İş Asistan Web Uygulaması",
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotelManagement/History.vue"
              ),
          },
          {
            path: "/hotelManagement/",
            name: "hotelManagementNew",
            meta: {
              title:
                "Yeni Konuşma | AI Otel Asistanı | İş Asistan Web Uygulaması",
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotelManagement/NewSession.vue"
              ),
          },
        ],
      },
      {
        path: "/hotelManagement/staffList",
        name: "hotelManagementStaffList",
        meta: {
          title:
            "AI Asistan Modülü Personel Listesi | İş Asistan Web Uygulaması",
            requiresPermission: "TENANT_ADMIN"
        },
        component: () =>
          import("@/presentation/views/modules/hotelManagement/StaffList.vue"),
      },
      {
        path: "/hotelManagement/staffHistory/",
        name: "hotelManagementStaffHistory",
        meta: {
          requiresPermission: "TENANT_ADMIN"
        },
        component: () =>
          import(
            "@/presentation/views/modules/hotelManagement/StaffChatListHistory.vue"
          ),
        children: [
          {
            path: "/hotelManagement/staffList/:staffId",
            name: "hotelManagementStaffChatListHistory",
            meta: {
              title:
                "Personel Konuşma Geçmişleri | AI Otel Asistanı | İş Asistan Web Uygulaması",
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotelManagement/StaffChatListPage.vue"
              ),
          },
          {
            path: "/hotelManagement/staffList/:staffId/history/:sessionId",
            name: "hotelManagementStaffChatHistory",
            meta: {
              title:
                "Personel Konuşma Geçmişi | AI Otel Asistanı | İş Asistan Web Uygulaması",
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotelManagement/StaffChatHistory.vue"
              ),
          },
        ],
      },
      {
        path: "/moduleList",
        name: "moduleList",
        meta: { title: "Modüller | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN" },
        component: () => import("@/presentation/views/tenant/ModuleList.vue"),
      },
      {
        path: "/moduleDetail/:id",
        name: "moduleDetail",
        meta: { title: "Modül Detayı | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN" },
        component: () => import("@/presentation/views/tenant/ModuleDetail.vue"),
      },
      {
        path: "/hotelManagementDetail",
        name: "hotelManagementDetail",
        meta: {
          title: "AI Otel Asistan Modül Detayları | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN"
        },
        component: () =>
          import(
            "@/presentation/views/modules/hotelManagement/ModuleDetail.vue"
          ),
      },
      {
        path: "/pollsterCreate",
        name: "pollsterCreate",
        meta: { title: "Anket Oluştur | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/tenant/CreatePollster.vue"
          ),
      },
      {
        path: "/pollsterDetail",
        name: "pollsterDetail",
        meta: { title: "Anketör Modül Detayları | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/tenant/ModuleDetail.vue"
          ),
      },
      {
        path: "/pollsterEmails",
        name: "pollsterEmails",
        meta: {
          title: "Anketör E-posta Adresleri | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN"
        },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/tenant/EmailAddresses.vue"
          ),
      },
      {
        path: "/startPoll",
        name: "startPoll",
        meta: { title: "Ankete Başla | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/user/StartPollster.vue"
          ),
      },
      {
        path: "/pollsterUser",
        name: "pollsterUser",
        meta: { title: "Anketör | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import("@/presentation/views/modules/pollster/user/Pollster.vue"),
      },
      {
        path: "/interviewCreate",
        name: "interviewCreate",
        meta: { title: "Mülakat Oluştur | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/CreateInterview.vue"
          ),
      },
      {
        path: "/startInterview",
        name: "startInterview",
        meta: { title: "Mülakata Başla | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/interview/user/StartInterview.vue"
          ),
      },
      {
        path: "/interviewDetail",
        name: "interviewDetail",
        meta: {
          title: "Mülakatör Modül Detayları | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN"
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/ModuleDetail.vue"
          ),
      },
      {
        path: "/interviewUser",
        name: "interviewUser",
        meta: { title: "Mülakatör | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import("@/presentation/views/modules/interview/user/Interview.vue"),
      },
      {
        path: "/accountStatus",
        name: "accountStatus",
        meta: { title: "Hesap Durumu | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import("@/presentation/views/tenant/AccountStatus.vue"),
      },
      {
        path: "/accountTypes",
        name: "accountTypes",
        meta: { title: "Hesap Tipleri | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN" },
        component: () => import("@/presentation/views/tenant/AccountTypes.vue"),
      },
      {
        path: "/staffList",
        name: "staffList",
        meta: { title: "Personel Listesi | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN" },
        component: () => import("@/presentation/views/tenant/StaffList.vue"),
      },
      {
        path: "/addStaff",
        name: "addStaff",
        meta: { title: "Personel Ekle | İş Asistan Web Uygulaması", requiresPermission: "TENANT_ADMIN" },
        component: () => import("@/presentation/views/tenant/AddStaff.vue"),
      },
      // Ogza
      {
        path: "/allModule",
        name: "allModule",
        meta: { title: "Modül Listesi | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () => import("@/presentation/views/ogza/ModuleList.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        meta: { title: "Şirketler | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () => import("@/presentation/views/ogza/Companies.vue"),
      },
      {
        path: "/createCompany",
        name: "createCompany",
        meta: { title: "Şirket Oluştur | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () => import("@/presentation/views/ogza/CreateCompany.vue"),
      },
      {
        path: "/createModule",
        name: "createModule",
        meta: { title: "Modül Oluştur | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () => import("@/presentation/views/ogza/CreateModule.vue"),
      },
      {
        path: "/chatGptSettings",
        name: "chatGptSettings",
        meta: { title: "ChatGpt Ayarları | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import("@/presentation/views/ogza/ChatGptSettings.vue"),
      },
    ],
  },
  {
    path: "/guest",
    component: () => import("@/presentation/layout/Guest.vue"),
    children: [
      {
        path: "/doInterview",
        name: "doInterview",
        meta: { title: "Mülakat | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import("@/presentation/views/modules/interview/guest/Interview.vue"),
      },
      {
        path: "/startToInterview",
        name: "startToInterview",
        meta: { title: "Mülakata Başla | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/interview/guest/StartInterview.vue"
          ),
      },
      {
        path: "/doPoll",
        name: "doPoll",
        meta: { title: "Anket | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import("@/presentation/views/modules/pollster/guest/Pollster.vue"),
      },
      {
        path: "/startToPoll",
        name: "startToPoll",
        meta: { title: "Ankete Başla | İş Asistan Web Uygulaması", requiresPermission: "OGZA_ADMIN" },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/guest/StartPollster.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/presentation/layout/Auth.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        meta: { title: "Giriş Yap | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/Login.vue"),
      },
      {
        path: "/setProfileInfo",
        name: "setProfileInfo",
        meta: { title: "Profil Oluştur | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/SetProfileInfo.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        meta: { title: "Şirketler | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/Companies.vue"),
      },
      {
        path: "/createCompany",
        name: "createCompany",
        meta: { title: "Şirket Oluştur | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/CreateCompany.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: { title: "400 - Sayfa Bulunamadı" },
    component: () => import("@/presentation/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: { title: "500 - Bulunamadı" },
    component: () => import("@/presentation/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const jwtTokenManager = new JwtTokenManager();

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || "İş Asistan Web Uygulaması";
  const encryptedUserRole = localStorage.getItem("roleCode");

  const decryptUserRole =
  AES256EncryptionService.decrypt(encryptedUserRole); // kullanıcının rolu
  
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  console.log(to)

if (to.name === ROUTE_PAGES.SIGN_IN) {
    if (!jwtTokenManager.getToken()) {
      next();
      return;
    } else {
      store.dispatch(Actions.VERIFY_AUTH);
      next({ name: ROUTE_PAGES.DASHBOARD });
      return;
    }
  } else if (to.name === ROUTE_PAGES.LANDING_PAGE) {
    next();
  } else {
    if (jwtTokenManager.getToken()) {
      if (to.meta.requiresPermission) {
        if (decryptUserRole == to.meta.requiresPermission) {
          next();
          return;
        } else {
          next({ name: ROUTE_PAGES.FORBIDDEN });
          return;
        }
      } else {
        next();
        return;
      }
    } else {
      next({ name: ROUTE_PAGES.SIGN_IN });
      return;
    }
  }
});

export default router;
