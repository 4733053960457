export const MAPPER = {
    LOGIN: "LoginMapper",
    RESET_PASSWORD: "ResetPasswordMapper",
    FORGOT_PASSWORD: "ForgotPasswordMapper",
    GENERATE_OTP: "GenerateOTPMapper",
    VERIFY_OTP: "VerifyOTPMapper",
    COUNTRY_LIST: "CountryListMapper",
    UPDATE_USER: "UpdateUserMapper",
    ABOUT_USER: "AboutUserMapper",
    CHANGE_TENANT_ESTATE: "ChangeTenantEstateMapper",
    SEND_MESSAGE: "SendMessageMapper",
    RETRY_ANSWER: "RetryAnswerMapper",
    SESSION_LIST: "SessionListMapper",
    STAFF: {
      STAFF_LIST: "StaffListMapper",
      ADD_STAFF: "AddStaffMapper",
      DELETE_STAFF: "DeleteStaffMapper",
    },
    PRODUCT: {
      PRODUCT_LIST: "ProductListMapper",
      BUY_PRODUCT: "BuyProductMapper",
      UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingMapper",
    },
    STATISTIC: "StatisticMapper",
  };