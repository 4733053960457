import { BaseDomain } from "@/core/base/BaseDomain";
import { TenantRoleResponseDto } from "../dto/TenantRoleResponseDto";

export class TenantEstateModel extends BaseDomain {
  id?: number;
  name?: string | null;
  shortCode?: string | null;
  tenantRole?: TenantRoleResponseDto;

  constructor(
    id?: number,
    name?: string | null,
    shortCode?: string | null,
    tenantRole?: TenantRoleResponseDto,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.shortCode = shortCode;
    this.tenantRole = tenantRole;
  }
}
